import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
} from "../actions/activeType";

let initialState = {
  isLoading: true,
  users: [],
};

export default function users(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USERS:
      return Object.assign({}, state, {
        isLoading: true,
        users: [],
      });
    case FETCH_USERS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        users: payload && (payload.users || []),
      });
    case FETCH_USERS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        users: [],
      });
    default:
      return state;
  }
}

import { post } from 'axios'
import { urls } from '../constants'

class Auth {

	constructor() {
		this.authenticated = false
	}

	checkAuth = _ => {
		let user = localStorage.getItem('auth_user')
		if (!user) {
			this.authenticated = false
			return new Promise((resolve, reject) => {
				reject(new Error("Unauthenticated"))
			})
		}

		user = JSON.parse(user)
		if (!user.user_id || !user.user_email || !user.accessToken) {
			this.authenticated = false
			return new Promise((resolve, reject) => {
				reject(new Error("Unauthenticated"))
			})
		} else {
			this.authenticated = true
		}

		return post(urls.refreshToken, { refresh: user.refreshToken })
	}

}

export default new Auth()

import React from 'react'
import { connect } from 'react-redux'
import Auth from '../Auth'
import Login from '../../views/Pages/Login/Login';
import { isExpired } from 'react-jwt';
import { CURRENT_USER, REFRESH_ACCESS_TOKEN } from '../../actions/activeType';

export const ProtectedRoute = (Component) => {
	return connect()(
		class ProtectedRouteComponent extends React.Component {

			state = {
				auth: false
			}

			async componentDidMount() {
				let user = localStorage.getItem('auth_user')
				if (!user) {
					this.setState({ auth: false })
					return
				}

				user = JSON.parse(user) || {}
				if (!user.user_id || !user.user_email || !user.accessToken) {
					this.setState({ auth: false })
					return
				}

				if (isExpired(user.accessToken)) return

				await Auth.checkAuth().then(res => {
					const { data: { access } } = res
					if (access) {
						this.setState({ auth: true })
						this.props.dispatch({ type: CURRENT_USER })
						this.props.dispatch({ type: REFRESH_ACCESS_TOKEN, payload: { accessToken: access } })
					} else {
						this.setState({ auth: false })
					}
				}).catch(err => {
					this.setState({ auth: false })
				})
			}

			render() {
				let shouldPushHistory = this.props.history.location.pathname !== window.location.pathname
				if (this.state.auth) {
					if (shouldPushHistory) window.history.pushState({}, "", this.props.history.location.pathname)
					return <Component {...this.props} />
				} else {
					if (shouldPushHistory || window.location.pathname !== '/login') window.history.pushState({}, "", '/login')
					return <Login />
				}
			}

		}
	)
}

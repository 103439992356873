export default () => dispatch => action => {
    if (!action.request || !action.request.url) {
        return dispatch(action);
    }

    const { type } = action;
    let { url, method, data, headers } = action.request;
    headers = action.headers || { 'Accept': 'application/json' }

    delete action.request;
    dispatch(action);

    return fetch(url, {
        method: method || 'GET',
        body: data ? JSON.stringify(data) : undefined,
        headers,
    })
        .then(response => {
            return response.json()
        })
        .then(response => dispatch({
            type: type + '_SUCCESS',
            payload: response
        }))
        .catch(err => dispatch({
            type: type + '_ERROR',
            error: err.message || 'Unknown',
            status: (err.response && err.response.status) || 0
        }));
}
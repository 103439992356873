import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import api from '../middlewares/api'
import { users, loggedInUser } from '../reducers'

function store(initialState) {
    return createStore(
        combineReducers({
            users,
            loggedInUser
        }),
        initialState,
        applyMiddleware(thunk, api)
    )
}

export default store;

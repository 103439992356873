import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_ERROR,
  LOGOUT_REQUEST,
  CURRENT_USER,
  REFRESH_ACCESS_TOKEN,
} from "../actions/activeType";

let initialState = {
  isLoading: false,
  user: {},
  isAuthenticated: false,
};

export default function loggedInUser(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER:
      let user = localStorage.getItem("auth_user") || "{}";
      user = JSON.parse(user);
      return Object.assign({}, state, {
        isLoading: false,
        isAuthenticated: true,
        user,
      });

    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        isAuthenticated: false,
      });
    case LOGIN_REQUEST_SUCCESS:
      if (payload) {
        let authUser = {};
        if (payload.user) Object.assign(authUser, { ...payload.user });
        if (payload.accessToken)
          Object.assign(authUser, { accessToken: payload.accessToken });
        if (payload.refreshToken)
          Object.assign(authUser, { refreshToken: payload.refreshToken });

        localStorage.setItem("auth_user", JSON.stringify(authUser));
      }

      return Object.assign({}, state, {
        isLoading: false,
        user: payload && (payload.user || {}),
        isAuthenticated: payload && payload.user ? true : false,
      });
    case LOGIN_REQUEST_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        isAuthenticated: false,
      });

    case REFRESH_ACCESS_TOKEN:
      let authUser = JSON.parse(localStorage.getItem("auth_user")) || {};
      if (payload) {
        if (payload.accessToken)
          Object.assign(authUser, { accessToken: payload.accessToken });
        localStorage.setItem("auth_user", JSON.stringify(authUser));
      }

      return Object.assign({}, state, {
        isLoading: false,
        user: authUser || {},
        isAuthenticated: authUser && authUser.user ? true : false,
      });
    case LOGOUT_REQUEST:
      localStorage.removeItem("auth_user");
      return Object.assign({}, state, {
        isLoading: false,
        isAuthenticated: false,
      });

    default:
      return state;
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { connect } from "react-redux";
import { post } from "axios";
import { LOGIN_REQUEST_SUCCESS } from "../../../actions/activeType";
import { urls } from "../../../constants";
import { routes } from "../../../constants";

class Login extends Component {
  state = {
    loginErrorMessage: null,
    passwordResetError: null,
    passwordResetSuccess: null,
    showModal: false,
    disableLoginBtn: false,
    disableForgetPasswordBtn: false,
    disablePasswordResetBtn: false,
    showPassword: false,
  };

  handleLogin = async (e) => {
    e.preventDefault();
    this.setState({ disableLoginBtn: true });
    const form = e.target;
    const { value: email } = form.querySelector("input[name=email]");
    const { value: password } = form.querySelector("input[name=password]");

    if (!email || !password) {
      this.setState({ disableLoginBtn: false });
      this.setState({ loginErrorMessage: "Email and username is required." });
      return;
    }

    let res = await post(urls.login, { email, password })
      .then((user) => user)
      .catch((err) => {
        if (err.response && err.response.data) {
          let { detail } = err.response.data;
          this.setState({ loginErrorMessage: detail });
        }
      });

    this.setState({ disableLoginBtn: false });

    if (!res) return;

    let {
      access: accessToken,
      refresh: refreshToken,
      user,
      errors,
      detail,
    } = res.data;

    if (!user && detail) {
      this.setState({ loginErrorMessage: detail, disableLoginBtn: false });
      return;
    }

    if (errors) {
      const { msg } = errors;
      this.setState({ loginErrorMessage: msg, disableLoginBtn: false });
      return;
    }

    if (user) {
      this.props.dispatch({
        type: LOGIN_REQUEST_SUCCESS,
        payload: { user, accessToken, refreshToken },
      });
      window.location.href = routes.home;
    }
  };

  toogleModal = () => {
    this.setState(({ showModal }) => ({
      showModal: !showModal,
    }));
  };

  toggleShowPassword = () => {
    this.setState(({ showPassword }) => ({
      showPassword: !showPassword,
    }));
  };

  handleForgetPassword = async (e) => {
    e.preventDefault();
    this.setState({
      showModal: true,
      passwordResetError: null,
      passwordResetSuccess: null,
      disablePasswordResetBtn: true,
    });
    const form = e.target;
    const { value: email } = form.querySelector("input[name=email]");
    let res = await post(urls.passwordReset, { email })
      .then((res) => res)
      .catch((err) => {
        if (err && err.response && err.response.data) {
          let { detail } = err.response.data;
          this.setState({ passwordResetError: detail });
        } else {
          this.setState({
            passwordResetError: "Something went wrong. Please try again.",
          });
        }
      });

    if (res && res.data && res.data.email) {
      form.reset();
      this.setState({
        passwordResetSuccess:
          "Password reset link sent. Please check your email.",
      });
      setTimeout(() => {
        this.setState({ showModal: false });
      }, 2000);
    }

    this.setState({ disablePasswordResetBtn: false });
  };

  render() {
    const { loginErrorMessage, passwordResetError, passwordResetSuccess } =
      this.state;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="10">
              <div className="text-center">
                <img
                  alt="genese-logo"
                  className="logo"
                  src="assets/img/logo-genese.svg"
                />
                <h1>Email Sanitization</h1>
                <p>
                  Enhance your IT solution with the a precise and high
                  performing email validation and email verification
                  capabilities. <br />
                  Verify your emails in real-time, or use bulk email
                  verification to clean whole batches at once.
                </p>
              </div>
              <CardGroup className="mt-4">
                <Card className="p-4">
                  <CardBody>
                    <Row>
                      <Col md="6" className="col-flex">
                        <img
                          alt="login-banner"
                          src={"assets/img/login-banner.svg"}
                          className="banner-img"
                        />
                      </Col>
                      <Col md="6">
                        {loginErrorMessage && (
                          <p className="alert alert-warning">
                            {loginErrorMessage}
                          </p>
                        )}

                        <Form onSubmit={this.handleLogin}>
                          <h3>Login</h3>
                          <p className="text-muted">Sign in to your account</p>
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>@</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="email"
                              required
                              name="email"
                              placeholder="Email"
                              autoComplete="email"
                            />
                          </InputGroup>
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              required
                              name="password"
                              placeholder="Password"
                              autoComplete="current-password"
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText onClick={this.toggleShowPassword}>
                                {!this.state.showPassword && (
                                  <i className="fa fa-eye" />
                                )}
                                {this.state.showPassword && (
                                  <i className="fa fa-eye-slash" />
                                )}
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          <Row>
                            <Col xs="12">
                              <Button color="primary" className="px-4">
                                Login
                              </Button>{" "}
                              &nbsp;
                              <Link to="/register" style={{ color: "#fff" }}>
                                <Button className="px-4 btn-link">
                                  Register
                                </Button>
                              </Link>
                            </Col>
                            <Col xs="12" className="text-left">
                              <Button
                                color="link"
                                className="px-0 mt-3"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({ showModal: true });
                                }}
                              >
                                Forgot password?
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
              <p className="text-center mt-4">
                <small>
                  &copy; 2020{" "}
                  <a
                    href="https://www.genesesolution.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Genese Solution
                  </a>
                </small>
              </p>
            </Col>
          </Row>

          <Modal
            isOpen={this.state.showModal}
            toggle={() => this.setState({ showModal: !this.state.showModal })}
          >
            <ModalHeader
              toggle={() => {
                this.setState({
                  showModal: !this.state.showModal,
                  passwordResetError: null,
                  passwordResetSuccess: null,
                });
              }}
            >
              Forgot Password
            </ModalHeader>
            <ModalBody>
              {passwordResetError && (
                <p className="alert alert-warning">{passwordResetError}</p>
              )}
              {passwordResetSuccess && (
                <p className="alert alert-success">{passwordResetSuccess}</p>
              )}
              <Form onSubmit={this.handleForgetPassword}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>@</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="email"
                    required
                    name="email"
                    placeholder="Email"
                    autoComplete="email"
                  />
                </InputGroup>
                <Row>
                  <Col xs="6">
                    <Button
                      disabled={this.state.disablePasswordResetBtn}
                      color="primary"
                      className="px-4"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    );
  }
}

export default connect()(Login);

// export const apiBaseUrl = "http://65.1.101.44:8000";
// export const apiBaseUrl = "https://backend.emailchecker.genesesolution.com";
export const apiBaseUrl = "https://demobackend.emailchecker.genesesolution.com";
// export const apiBaseUrl = "http://127.0.0.1:8000";

export const login = apiBaseUrl + "/api/token/"

export const refreshToken = apiBaseUrl + "/api/token/refresh/"

export const signup = apiBaseUrl + "/api/accounts/signup/"

/**
 * won't need to do any work possibly in client side
 * email will be verified once the user clicks
 * the email verification link provided in email
 */
export const signupVerify = apiBaseUrl + "/api/accounts/signup/verify/"

// possibly won't be needed, as rest api don't use sessions
export const logout = apiBaseUrl + "/api/accounts/logout/"

/**
 * Email will be sent to user when password reset is clicked
 * Email will contain password reset code
 */
export const passwordReset = apiBaseUrl + "/api/accounts/password/reset/"

/**
 * will be replacing CODE with actual code
 * verify code
 * Uncertain about the purpose of this url
 */
export const passwordResetVerify = apiBaseUrl + "/api/accounts/password/reset/verify/?code=CODE"

/**
 * URL to post new password and code
 */
export const passwordResetVerified = apiBaseUrl + "/api/accounts/password/reset/verified/"

export const passwordChange = apiBaseUrl + "/api/accounts/password/change/"

/**
 * Get details of currently logged in user
 * most likely will be getting those details from localStorage
 */
export const myDetail = apiBaseUrl + "/api/accounts/users/me/"

/**
 * payload file
 */
export const checkDuplicates = apiBaseUrl + "/email-sanitization/check-duplicates/"

export const organizationList = apiBaseUrl + "/email-sanitization/sanitization-organization/"

export const saveEmailSanitizationRequest = id => apiBaseUrl + `/email-sanitization/save-request/${id}/`

export const getSaveRequest = () => apiBaseUrl + '/email-sanitization/save-request/'

export const downloadSanitizedCsv = apiBaseUrl + '/email-sanitization/download-csv-sanitized/'

/**
 * @param user_id: (required for only admin user)
 *	call this endpoint to get sanitization histories of different users.
 */
export const organizationHistory = apiBaseUrl + '/email-sanitization/organization-history/'

export const updateOrganization = id => `${apiBaseUrl}/email-sanitization/sanitization-organization/${id}/`

// Api Links for the bouncer keys
export const bouncerKeyList = apiBaseUrl + '/email-sanitization/x-api-key/'
export const updateBouncerKey = id=> apiBaseUrl + `/email-sanitization/x-api-key/${id}/`
export const getBouncerKey = id=> apiBaseUrl + `/email-sanitization/x-api-key/${id}/`
export const getCredits = id=> apiBaseUrl + `/email-sanitization/bouncer-credits/${id}`

export const CURRENT_USER = 'CURRENT_USER'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS'
export const LOGIN_REQUEST_ERROR = 'LOGIN_REQUEST_ERROR'

export const REFRESH_ACCESS_TOKEN = 'REFRESH_ACCESS_TOKEN';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'

export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR'
export const ADD_USERS = 'ADD_USERS'

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import routes from './constants/routes'
import './App.scss';
import { ProtectedRoute } from './components/route/ProtectedRoute'
// import 'bootstrap/dist/css/bootstrap.min.css'

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
// const Login = React.lazy( () => import('./components/login/index'))
const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const Register = React.lazy(() => import('./views/Pages/Register/Register'));
const ChangePassword = React.lazy(() => import('./views/Pages/ChangePassword'));

class App extends Component {

	render() {
		return (
			<Router>
				<React.Suspense fallback={loading()}>
					<Switch>
						<Route exact={true} path={routes.userLogin} component={withRouter(Login)} />
						<Route exact={true} path="/register" name="Register Page" render={props => <Register {...props} />} />
						<Route exact={true} path="/change-password" name="Change password Page" render={props => <ChangePassword {...props} />} />
						<Route path="/" name="Home" component={ProtectedRoute(DefaultLayout)} />
					</Switch>
				</React.Suspense>
			</Router>
		);
	}

}

export default App;
